import { animateFadeLeft, animateFadeRight, animateFadeUp } from "../../../assets/src/js/includes/animations";

const blocks = document.querySelectorAll(
    `.wp-block-blufish-agent-profile`
);

if (blocks.length > 0) {
    blocks.forEach((block) => {
        const image = block.querySelector(`.js-image`);
        const items = block.querySelectorAll(`.heading, .js-line , .js-contact-info > *`);

        let mm = gsap.matchMedia();

        mm.add("(min-width: 901px)", () => {
            animateFadeRight(image);
            animateFadeLeft(items, {
                stagger: 0.1,
            });
        });

        mm.add("(max-width: 900px)", () => {
            animateFadeUp(image);
            animateFadeUp(items, {
                stagger: 0.1,
            });
        });
    });
}