/**
 *
 * HELPERS
 *
 */
export const animateFade = (element, config = {}) => {
    if (element) {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: element,
                // markers: true,
            },
        });

        tl.from(element, {
            opacity: 0,
            duration: 1,
            ...config,
        });

        return tl;
    }
};

export const animateFadeUp = (element, config = {}) => {
    if (element) {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: element,
                // markers: true,
            },
        });

        tl.from(element, {
            y: 100,
            opacity: 0,
            duration: 1,
            ...config,
        });

        return tl;
    }
};

export const animateFadeDown = (element, config = {}) => {
    if (element) {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: element,
            },
        });

        tl.from(element, {
            y: -100,
            opacity: 0,
            duration: 1,
            ...config,
        });

        return tl;
    }
};

export const animateFadeLeft = (element, config = {}) => {
    if (element) {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: element,
                // markers: true,
            },
        });

        tl.from(element, {
            x: 100,
            opacity: 0,
            duration: 1,
            ...config,
        });

        return tl;
    }
};

export const animateFadeRight = (element, config = {}) => {
    if (element) {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: element,
            },
        });

        tl.from(element, {
            x: -100,
            opacity: 0,
            duration: 1,
            ...config,
        });

        return tl;
    }
};


/**
 *
 *
 * GLOBAL ANIMATIONS
 *
 *
 */
const animateHeader = () => {
    const logo = document.querySelector(`.js-site-header-logo`);
    const banner = document.querySelector(`.js-site-banner`);
    const menuItems = document.querySelectorAll(
        `.js-site-header-menu > .js-menu-item`
    );

    animateFadeDown(banner, {
        delay: 0.5,
    });

    if (menuItems.length > 0) {
        gsap.from([logo, ...menuItems], {
            y: -100,
            opacity: 0,
            duration: 1,
            stagger: 0.05,
        });
    }

    const secondary = document.querySelector(`.js-site-header-secondary`);
    if (secondary) {
        const secondaryItems = secondary.querySelectorAll(`.js-agent, .js-site-header-secondary-menu > *`);
        animateFade(secondaryItems, {
            stagger: 0.05,
        });
    }
};

const animateFooter = () => {
    const groupItems = document.querySelectorAll(
        `.js-site-footer-animate-group > *`
    );

    if (groupItems.length > 0) {
        animateFadeUp(groupItems, {
            stagger: 0.05,
        });
    }
};

const animateContentSet = () => {
    const contentSets = document.querySelectorAll(`.js-content-set:not(.ignore-global-animation)`);

    if (contentSets.length > 0) {
        contentSets.forEach((contentSet) => {
            const heading = contentSet.querySelector(`.heading`);
            const content = contentSet.querySelector(`.content`);
            const buttons = contentSet.querySelectorAll(`.js-button`);
            const reverse = contentSet.closest(`.reverse`) ? true : false;

            animateFadeDown(heading);
            animateFadeUp(content);

            if (buttons.length > 0) {
                animateFadeUp(buttons, {
                    stagger: 0.1,
                });
            }
        });
    }
};

const animateVerticalLines = () => {
    const els = document.querySelectorAll(`.js-vertical-line:not(.no-animation)`);

    if (els.length > 0) {
        els.forEach((el) => {
            if (el.classList.contains('js-line--bottom')) {
                gsap.from(el, {
                    yPercent: 100,
                    opacity: 0,
                    duration: 1,
                    scrollTrigger: {
                        trigger: el,
                        start: `-${el.offsetHeight} bottom`,
                        end: 'bottom 10%',
                    },
                });
            } else {
                gsap.from(el, {
                    yPercent: -100,
                    opacity: 0,
                    duration: 1,
                    scrollTrigger: {
                        trigger: el,
                    },
                });
            }
        });
    }
};

export default () => {
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.defaults({
        toggleActions: 'play resume none reverse',
        start: 'top 90%',
        end: 'bottom 10%',
    });

    animateHeader();
    animateContentSet();
    animateVerticalLines();
    animateFooter();
};
